import React, { useState } from 'react'
import { Alert, Button } from 'antd'
import { LoadingOutlined, LoginOutlined } from '@ant-design/icons'
import { useLocation, useNavigate } from 'react-router-dom'
import { useConfirmSignup, useLogin } from './auth-hooks'
import { setSessionCookies } from './auth-helpers'
import InputC from '../shared/components/InputC'
import { IconLockSquareRounded, IconMail } from '@tabler/icons-react'

const ConfirmSignup = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const confirmSignup = useConfirmSignup()
  const login = useLogin()

  const state: { username: string; password: string } = location.state || {}

  const [error, setError] = useState('')
  const [username, setUsername] = useState(state.username || '')
  const [confirmationCode, setConfirmationCode] = useState('')

  const handleConfirmSignup = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    setError('')

    confirmSignup.mutate(
      {
        username,
        confirmationCode
      },
      {
        onSuccess: () => {
          login.mutate(
            {
              username,
              password: state.password
            },
            {
              onSuccess: (response) => {
                setSessionCookies()
                navigate('/')
                setUsername('')
                setConfirmationCode('')
              },
              onError: (err) => {
                setError(err.message)
              }
            }
          )
        },
        onError: (err) => {
          setError(err.message)
        }
      }
    )
  }

  return (
    <form
      onSubmit={handleConfirmSignup}
      style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        rowGap: '20px'
      }}
    >
      <div style={{ fontSize: '1.3rem' }}>Confirm your Email to continue.</div>

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          rowGap: '15px',
          width: '100%'
        }}
      >
        <InputC
          disabled
          onChange={(e) => setUsername(e.target.value)}
          placeholder="Enter your email or username"
          prefix={<IconMail style={{ marginRight: '5px' }} />}
          required
          style={{ background: '#a2bcff18' }}
          type="text"
          value={username}
        />

        <InputC
          onChange={(e) => setConfirmationCode(e.target.value)}
          placeholder="Verification code"
          prefix={<IconLockSquareRounded style={{ marginRight: '5px' }} />}
          required
          style={{ background: '#a2bcff18' }}
          type="text"
          value={confirmationCode}
        />

        {error && (
          <Alert
            closable
            message={error}
            style={{ width: '100%' }}
            type="error"
          />
        )}
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            width: '100%',
            alignItems: 'center',
            marginTop: '10px'
          }}
        >
          <Button
            color="primary"
            disabled={
              !username ||
              !confirmationCode ||
              confirmSignup.isPending ||
              login.isPending
            }
            htmlType="submit"
            shape="round"
            variant="solid"
          >
            Verify Email{' '}
            {confirmSignup.isPending || login.isPending ? (
              <LoadingOutlined />
            ) : (
              <LoginOutlined />
            )}
          </Button>
        </div>
      </div>
    </form>
  )
}

export default ConfirmSignup
