import { IconUser, IconCheck, IconCopy } from '@tabler/icons-react'
import { Avatar, List, Tooltip, Typography } from 'antd'
import { useState } from 'react'
import { User } from '../../../api/api-client'

interface Props {
  user: User
}

function UserItem(props: Props) {
  const [copied, setCopied] = useState<boolean>(false)
  const handleCopy = (text: string) => {
    navigator.clipboard.writeText(text)
    setCopied(true)
    setTimeout(() => {
      setCopied(false)
    }, 2000)
  }

  return (
    <List.Item>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          columnGap: '10px'
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            columnGap: '10px'
          }}
        >
          <Avatar icon={<IconUser />} />
          <Typography.Text>{props.user.email}</Typography.Text>
        </div>
        <Tooltip title={copied ? 'Copied' : 'Copy'}>
          {copied ? (
            <IconCheck size={16} style={{ color: 'green' }} />
          ) : (
            <IconCopy
              onClick={() => handleCopy(props.user.email as string)}
              size={16}
              style={{ cursor: 'pointer' }}
            />
          )}
        </Tooltip>
      </div>
    </List.Item>
  )
}

export default UserItem
