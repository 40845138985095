import React, { useState } from 'react'
import { Alert, Button, Typography } from 'antd'
import { LoadingOutlined, LoginOutlined } from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'
import { useLogin } from './auth-hooks'
import { setSessionCookies } from './auth-helpers'
import InputC from '../shared/components/InputC'
import { IconCloudLock, IconMail } from '@tabler/icons-react'

const Login = () => {
  const login = useLogin()
  const navigate = useNavigate()

  const [error, setError] = useState('')
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')

  const handleLogin = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    setError('')

    login.mutate(
      {
        username,
        password
      },
      {
        onSuccess: () => {
          setSessionCookies()
          navigate('/')
          setUsername('')
          setPassword('')
        },
        onError: (err) => {
          setError(err.message)
        }
      }
    )
  }

  return (
    <form
      onSubmit={handleLogin}
      style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        rowGap: '20px'
      }}
    >
      <div style={{ fontSize: '1.3rem' }}>Sign in to Stickies Cloud!</div>

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          rowGap: '15px',
          width: '100%'
        }}
      >
        <InputC
          onChange={(e) => setUsername(e.target.value)}
          placeholder="Enter your email or username"
          prefix={<IconMail style={{ marginRight: '5px' }} />}
          required
          style={{ background: '#a2bcff18' }}
          type="text"
          value={username}
        />
        <InputC
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Password"
          prefix={<IconCloudLock style={{ marginRight: '5px' }} />}
          required
          style={{ background: '#a2bcff18' }}
          type="password"
          value={password}
        />
        {error && (
          <Alert
            closable
            message={error}
            style={{ width: '100%' }}
            type="error"
          />
        )}
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            alignItems: 'center',
            marginTop: '10px'
          }}
        >
          <Button
            onClick={() => navigate('/auth/forgot-password')}
            style={{ padding: 0 }}
            type="link"
          >
            Forgot password ?
          </Button>
          <Button
            color="primary"
            disabled={!username || !password || login.isPending}
            htmlType="submit"
            shape="round"
            variant="solid"
          >
            Login {login.isPending ? <LoadingOutlined /> : <LoginOutlined />}
          </Button>
        </div>
      </div>
      <div style={{ textAlign: 'center' }}>
        <Typography.Text>Do not have an account ?</Typography.Text>
        <Typography.Link onClick={() => navigate('/auth/signup')}>
          {' '}
          Create one now!
        </Typography.Link>
      </div>
    </form>
  )
}

export default Login
