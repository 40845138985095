import { Statistic, Typography } from 'antd'
import styled from 'styled-components'
import { useSearchStickies } from '../../hooks/stickies'
import { useMemo } from 'react'
import Skeleton from 'react-loading-skeleton'

export const randomNumber = (): number => {
  return Math.floor(Math.random() * (100 - 10 + 1)) + 10
}

function GeneralUsage() {
  const { stickies, isLoading } = useSearchStickies({
    query: ''
  })

  const {
    archivedStickies,
    unArchivedStickies,
    pinnedStickies,
    favouriteStickies
  } = useMemo(
    () => ({
      archivedStickies: stickies.filter((s) => s.archived === true),
      unArchivedStickies: stickies.filter((s) => s.archived !== true),
      pinnedStickies: stickies.filter((s) => s.pinned === true),
      favouriteStickies: stickies.filter((s) => s.favourite === true)
    }),
    [stickies]
  )

  return (
    <div>
      <Typography.Title level={4} style={{ color: '#525252' }}>
        Usage Metrics
      </Typography.Title>

      <Container>
        <Wrapper>
          <Statistic
            title="Total Stickies Created"
            valueRender={() =>
              isLoading ? <Skeleton width={50} /> : stickies.length
            }
            valueStyle={{ color: '#656565' }}
          />
        </Wrapper>
        <Wrapper>
          <Statistic
            title="Active Stickies"
            valueRender={() =>
              isLoading ? <Skeleton width={50} /> : unArchivedStickies.length
            }
            valueStyle={{ color: '#656565' }}
          />
        </Wrapper>
        <Wrapper>
          <Statistic
            title="Pinned Stickies"
            valueRender={() =>
              isLoading ? <Skeleton width={50} /> : pinnedStickies.length
            }
            valueStyle={{ color: '#656565' }}
          />
        </Wrapper>
        <Wrapper>
          <Statistic
            title="Favourite Stickies"
            valueRender={() =>
              isLoading ? <Skeleton width={50} /> : favouriteStickies.length
            }
            valueStyle={{ color: '#656565' }}
          />
        </Wrapper>
        <Wrapper>
          <Statistic
            title="Archived Stickies"
            valueRender={() =>
              isLoading ? <Skeleton width={50} /> : archivedStickies.length
            }
            valueStyle={{ color: '#656565' }}
          />
        </Wrapper>
      </Container>
    </div>
  )
}

export default GeneralUsage

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr)); /* Default: 4 columns */
  gap: 20px;
  padding: 15px 0;
  border-radius: 16px;

  @media (max-width: 1024px) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  @media (max-width: 768px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  @media (max-width: 480px) {
    grid-template-columns: 1fr;
  }
`
const Wrapper = styled.div`
  border: 1px solid #dfdfdf;
  padding: 15px;
  border-radius: 16px;
`
