import { create } from 'zustand'

interface InitialStates {
  toggleSideBar: boolean
  setToggleSideBar: (toggleSideBar: boolean) => void
}

export const useAppStore = create<InitialStates>((set) => ({
  toggleSideBar: false,
  setToggleSideBar: (toggleSideBar) => set(() => ({ toggleSideBar }))
}))
