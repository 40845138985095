import React, { useState } from 'react'
import { LoadingOutlined, LoginOutlined } from '@ant-design/icons'
import { Alert, Button, Typography } from 'antd'
import { useNavigate } from 'react-router-dom'
import { useSignup } from './auth-hooks'
import InputC from '../shared/components/InputC'
import { IconCloudLock, IconMail, IconUser } from '@tabler/icons-react'

const Signup = () => {
  const signup = useSignup()
  const navigate = useNavigate()

  const [email, setEmail] = useState('')
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [passwordConfirm, setPasswordConfirm] = useState('')
  const [error, setError] = useState('')

  const handleSignup = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    if (password && passwordConfirm && password !== passwordConfirm) {
      setError('Passwords do not match!')

      return
    }

    setError('')

    signup.mutate(
      {
        username,
        password,
        email
      },
      {
        onSuccess: () => {
          navigate('/auth/confirm-signup', {
            state: {
              username,
              password
            }
          })
          setUsername('')
          setPassword('')
          setPasswordConfirm('')
          setEmail('')
        },
        onError: (err) => {
          if (err.name === 'UserLambdaValidationException') {
            setError(err.message.split(':')[1])

            return
          }

          setError(err.message)
        }
      }
    )
  }

  return (
    <form
      onSubmit={handleSignup}
      style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        rowGap: '20px'
      }}
    >
      <div style={{ fontSize: '1.3rem' }}>
        Create new account for Stickies Cloud!
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          rowGap: '15px',
          width: '100%'
        }}
      >
        <InputC
          onChange={(e) => setUsername(e.target.value)}
          placeholder="Username"
          prefix={<IconUser style={{ marginRight: '5px' }} />}
          required
          style={{ background: '#a2bcff18' }}
          type="text"
          value={username}
        />
        <InputC
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Email"
          prefix={<IconMail style={{ marginRight: '5px' }} />}
          required
          style={{ background: '#a2bcff18' }}
          type="text"
          value={email}
        />
        <InputC
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Password"
          prefix={<IconCloudLock style={{ marginRight: '5px' }} />}
          required
          style={{ background: '#a2bcff18' }}
          type="password"
          value={password}
        />
        <InputC
          onChange={(e) => setPasswordConfirm(e.target.value)}
          placeholder="Confirm Password"
          prefix={<IconCloudLock style={{ marginRight: '5px' }} />}
          required
          style={{ background: '#a2bcff18' }}
          type="password"
          value={passwordConfirm}
        />
        {error && (
          <Alert
            closable
            message={error}
            style={{ width: '100%' }}
            type="error"
          />
        )}
        <div
          style={{
            width: '100%',
            display: 'flex',
            marginTop: '10px',
            alignItems: 'center',
            justifyContent: 'flex-end'
          }}
        >
          <Button
            color="primary"
            disabled={!username || !password || !email || signup.isPending}
            htmlType="submit"
            shape="round"
            variant="solid"
          >
            Sign Up Now{' '}
            {signup.isPending ? <LoadingOutlined /> : <LoginOutlined />}
          </Button>
        </div>
      </div>
      <div style={{ textAlign: 'center' }}>
        <Typography.Text>Already have an account ?</Typography.Text>
        <Typography.Link onClick={() => navigate('/auth/login')}>
          {' '}
          Login now!
        </Typography.Link>
      </div>
    </form>
  )
}

export default Signup
