import React, { useState } from 'react'
import { LoadingOutlined, LoginOutlined } from '@ant-design/icons'
import { Alert, Button, Typography } from 'antd'
import { useNavigate } from 'react-router-dom'
import { useForgetPassword } from './auth-hooks'
import InputC from '../shared/components/InputC'
import { IconPasswordUser } from '@tabler/icons-react'

const ForgotPassword = () => {
  const navigate = useNavigate()
  const forgetPassword = useForgetPassword()

  const [error, setError] = useState('')
  const [username, setUsername] = useState('')

  const handleForgotPassword = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setError('')

    forgetPassword.mutate(
      {
        username
      },
      {
        onSuccess: () => {
          navigate('/auth/reset-password', {
            state: {
              username
            }
          })
          setUsername('')
        },
        onError: (err) => {
          setError(err.message)
        }
      }
    )
  }

  return (
    <form
      onSubmit={handleForgotPassword}
      style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        rowGap: '20px'
      }}
    >
      <div>
        <div style={{ fontSize: '1.3rem' }}>Password recovery</div>
        <small style={{ color: '#4f4f4f', display: 'block', marginTop: '5px' }}>
          Enter your email or username to reset your password.
        </small>
      </div>

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          rowGap: '15px',
          width: '100%'
        }}
      >
        <InputC
          onChange={(e) => setUsername(e.target.value)}
          placeholder="Enter your Email or Username"
          prefix={<IconPasswordUser style={{ marginRight: '5px' }} />}
          required
          style={{ background: '#a2bcff18' }}
          type="text"
          value={username}
        />
        {error && (
          <Alert
            closable
            message={error}
            style={{ width: '100%' }}
            type="error"
          />
        )}
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            width: '100%',
            alignItems: 'center',
            marginTop: '10px'
          }}
        >
          <Button
            color="primary"
            disabled={!username || forgetPassword.isPending}
            htmlType="submit"
            shape="round"
            variant="solid"
          >
            Send Reset Code{' '}
            {forgetPassword.isPending ? <LoadingOutlined /> : <LoginOutlined />}
          </Button>
        </div>
      </div>
      <div style={{ textAlign: 'center' }}>
        <Typography.Link onClick={() => navigate('/auth/login')}>
          {' '}
          Back to Login!
        </Typography.Link>
      </div>
    </form>
  )
}

export default ForgotPassword
