import { Tabs } from 'antd'
import type { TabsProps } from 'antd'
import { useCallback, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { getTabItems } from '../../shared/components/TabItems'
import { StickiesLayout } from '../StickiesLayout'

function SharedStickies() {
  const location = useLocation()
  const navigate = useNavigate()
  const [activeTab, setActiveTab] = useState<string>(
    location.pathname.split('/')[2]
  )

  const items: TabsProps['items'] = getTabItems(['with-me', 'by-me'], activeTab)

  const onChange = useCallback(
    (key: string) => {
      setActiveTab(key)
      navigate(key)
    },
    [navigate]
  )

  return (
    <StickiesLayout
      showHeaderContent
      tabs={
        <Tabs
          className="custom-tabs"
          defaultActiveKey={activeTab}
          items={items}
          onChange={onChange}
        />
      }
    />
  )
}

export default SharedStickies
