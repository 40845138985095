import { BarsOutlined, AppstoreOutlined } from '@ant-design/icons'
import { IconRefresh, IconSearch } from '@tabler/icons-react'
import { Tooltip, Button, Segmented } from 'antd'
import { Outlet } from 'react-router-dom'
import SearchInput from '../shared/components/SearchInput'
import React from 'react'

export const StickiesLayout = (props: {
  tabs: React.ReactNode
  showHeaderContent?: boolean
}) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', rowGap: '16px' }}>
      {props.showHeaderContent && (
        <React.Fragment>
          {props.tabs}
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              columnGap: '16px',
              alignItems: 'center',
              justifyContent: 'space-between'
            }}
          >
            <div
              style={{
                display: 'flex',
                columnGap: '10px',
                alignItems: 'center'
              }}
            >
              <SearchInput
                enterButton={
                  <Button>
                    <IconSearch size={20} />
                  </Button>
                }
                itemScope
                placeholder="Search stickies..."
                size="large"
                style={{ width: 450, minWidth: 150 }}
              />
              <Tooltip title="Refresh table">
                <Button
                  color="primary"
                  icon={<IconRefresh size={20} />}
                  size="large"
                />
              </Tooltip>
            </div>

            <Segmented
              options={[
                { value: 'List', icon: <BarsOutlined /> },
                { value: 'Kanban', icon: <AppstoreOutlined /> }
              ]}
              size="large"
            />
          </div>
        </React.Fragment>
      )}

      <Outlet />
    </div>
  )
}
