import { create } from 'zustand'

interface InitialStates {
  showHeaderContent: boolean
  setShowHeaderContent: (showHeaderContent: boolean) => void
}

export const useStickiesStore = create<InitialStates>((set) => ({
  showHeaderContent: true,
  setShowHeaderContent: (showHeaderContent) =>
    set(() => ({ showHeaderContent }))
}))
