import { Table } from 'antd'
import type { TableProps } from 'antd'
import { Sticky } from '../../api/api-client'
import { useMemo } from 'react'

function TableC({
  stickies = [],
  loading,
  onRowClick,
  location
}: {
  stickies?: Sticky[]
  loading?: boolean
  location?: 'shared_by_me' | 'shared_with_me' | 'home'
  onRowClick?: (stickies: Sticky) => void
}) {
  const columns: TableProps<Sticky>['columns'] = useMemo(() => {
    return [
      {
        title: 'Title',
        dataIndex: 'title',
        key: 'title',
        render: (text) => <div>{text}</div>
      },
      {
        title: 'Note',
        dataIndex: 'note',
        key: 'note',
        render: (text) => <div>{text}</div>
      },
      {
        title: 'Website',
        dataIndex: 'url',
        key: 'url',
        render: (url) => <div>{url.domain}</div>
      },
      {
        title: location === 'shared_by_me' ? 'Shared with' : 'Shared by',
        dataIndex: 'user',
        key: 'user',
        render: (user) => <div>{user?.email || '-'}</div>
      },
      {
        title: 'Color',
        dataIndex: 'color',
        key: 'color',
        render: (color) => {
          return (
            <div
              style={{
                width: '20px',
                height: '20px',
                borderRadius: '50%',
                background: color.background
              }}
            />
          )
        }
      },
      {
        title: 'Expanded State',
        dataIndex: 'expanded_state',
        key: 'expanded_state',
        render: (expanded_state) => (
          <div>{expanded_state === 'collapsed' ? 'Collapsed' : 'Expanded'}</div>
        )
      },
      {
        title: 'Favourite',
        dataIndex: 'favourite',
        key: 'favourite',
        render: (favourite) => <div>{favourite ? 'Yes' : 'No'}</div>
      },
      {
        title: 'Pinned',
        dataIndex: 'pinned',
        key: 'pinned',
        render: (pinned) => <div>{pinned ? 'Yes' : 'No'}</div>
      },
      {
        title: 'Created at',
        dataIndex: 'created_at',
        key: 'created_at',
        render: (created_at) => <div>{created_at}</div>
      },
      {
        title: 'Updated at',
        dataIndex: 'updated_at',
        key: 'updated_at',
        render: (updated_at) => <div>{updated_at}</div>
      }
    ]
  }, [location])

  return (
    <Table<Sticky>
      columns={columns}
      dataSource={stickies}
      loading={loading}
      onRow={(record) => ({
        style: {
          cursor: 'pointer'
        },
        onClick: () => onRowClick?.(record)
      })}
      rowSelection={{ type: 'checkbox' }}
      scroll={{ x: 1000, y: 400 }}
    />
  )
}

export default TableC
