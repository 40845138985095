import { fetchAuthSession, signOut } from 'aws-amplify/auth'
import { useEffect, useState } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'

import { Avatar, Button, Layout, MenuProps, theme, Tooltip } from 'antd'
import { useMutation } from '@tanstack/react-query'
import Cookies from 'js-cookie'
import './app.css'
import AppSidebar from './AppSidebar'
import { useAppStore } from './stores/app-store'
import { useWindowSize } from 'react-use'
import {
  IconLayoutSidebarLeftCollapse,
  IconLayoutSidebarRightCollapse,
  IconTemplate,
  IconUser
} from '@tabler/icons-react'

import { Dropdown } from 'antd'

const useLogout = () => useMutation({ mutationFn: () => signOut() })

function App() {
  const logout = useLogout()
  const { Header, Content } = Layout
  const [collapsed, setCollapsed] = useState(false)
  const {
    token: { colorBgContainer, borderRadiusLG }
  } = theme.useToken()
  const navigate = useNavigate()

  const { width } = useWindowSize()

  const isMobile = width < 768
  const toggleSideBar = useAppStore((state) => state.toggleSideBar)
  const setToggleSideBar = useAppStore((state) => state.setToggleSideBar)

  useEffect(() => {
    fetchAuthSession().then((session) => {
      const token = session.tokens?.idToken?.toString()

      if (token) {
        Cookies.remove('token')
        Cookies.set('token', token)
      }
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleLogout = async () => {
    logout.mutate(undefined, {
      onSuccess: () => {
        Cookies.remove('token')
        navigate('/auth/login')
      },
      onError: (err) => {
        console.error('Logout error:', err)
      }
    })
  }

  const items: MenuProps['items'] = [
    {
      label: 'Profile',
      key: 'profile'
    },
    {
      label: 'Settings',
      key: 'settings'
    },
    {
      type: 'divider'
    },
    {
      label: 'Logout',
      key: 'logout',
      danger: true,
      onClick: handleLogout
    }
  ]

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <AppSidebar collapsed={collapsed} />
      <Layout>
        <Header
          style={{
            background: colorBgContainer,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '0 16px'
          }}
        >
          {isMobile && (
            <Button
              icon={<IconTemplate color="#9e9e9e" size={20} />}
              onClick={() => setToggleSideBar(!toggleSideBar)}
              shape="circle"
              style={{ padding: '15px' }}
              type="text"
              variant="solid"
            />
          )}
          {!isMobile && (
            <Button
              icon={
                collapsed ? (
                  <Tooltip placement="right" title={'Expand'}>
                    <IconLayoutSidebarRightCollapse color="#9e9e9e" />
                  </Tooltip>
                ) : (
                  <Tooltip placement="right" title={'Collapse'}>
                    <IconLayoutSidebarLeftCollapse color="#9e9e9e" />
                  </Tooltip>
                )
              }
              onClick={() => setCollapsed(!collapsed)}
              shape="circle"
              style={{ padding: '15px' }}
              type="text"
              variant="solid"
            />
          )}

          <Dropdown menu={{ items }} trigger={['click']}>
            <Avatar
              icon={<IconUser />}
              shape="circle"
              style={{
                cursor: 'pointer',
                background: theme.getDesignToken()['blue-10']
              }}
            />
          </Dropdown>
        </Header>
        <Content
          style={{
            margin: '16px',
            padding: '0px 16px 16px',
            minHeight: 280,
            background: colorBgContainer,
            borderRadius: borderRadiusLG
          }}
        >
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  )
}

export default App
