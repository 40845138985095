import EngagementMetrics from './engagement-metrics/EngagementMetrics'
import GeneralUsage from './general-usage/GeneralUsage'

function Dashboard() {
  return (
    <div
      style={{
        padding: '10px 0px',
        display: 'flex',
        flexDirection: 'column',
        gap: '20px'
      }}
    >
      <GeneralUsage />
      <EngagementMetrics />
    </div>
  )
}

export default Dashboard
