import { fetchAuthSession } from 'aws-amplify/auth'
import Cookies from 'js-cookie'

export const setSessionCookies = () => {
  fetchAuthSession().then((session) => {
    try {
      Cookies.remove('token')
      Cookies.set('token', session.tokens?.idToken?.toString() || '')
    } catch (error) {
      console.error('Error sending message to extension:', error)
    }
  })
}
