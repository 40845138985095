import { useSearchStickies } from '../../../hooks/stickies'
import TableC from '../../../shared/components/Table'

function ArchivedStickies() {
  const { stickies, isLoading } = useSearchStickies({
    query: '',
    archived: true
  })

  return <TableC loading={isLoading} stickies={stickies} />
}

export default ArchivedStickies
