import React, { useEffect } from 'react'
import { useCurrentUser } from '../hooks/auth'
import { useNavigate } from 'react-router-dom'
import { Spin } from 'antd'

function ProtectedRoute(props: {
  children: React.ReactElement
  successRoute?: string
  failureRoute?: string
}) {
  const navigate = useNavigate()
  const { isLoading, isError, userId } = useCurrentUser()

  useEffect(() => {
    if (isError) {
      navigate(props.failureRoute || '/auth/login')
    } else if (userId && props.successRoute) {
      navigate(props.successRoute)
    }
  }, [isError, userId, props.failureRoute, props.successRoute, navigate])

  if (isLoading) {
    return (
      <div
        style={{
          width: '100vw',
          height: '100vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <Spin />
      </div>
    )
  }

  return props.children
}

export default ProtectedRoute
