import React from 'react'
import ReactDOM from 'react-dom/client'
import reportWebVitals from './reportWebVitals'
import './index.css'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { router } from './routes/Routes'
import { RouterProvider } from 'react-router-dom'
import { Amplify } from 'aws-amplify'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

const client = new QueryClient()

Amplify.configure({
  Auth: {
    Cognito: {
      signUpVerificationMethod: 'link',
      userPoolId: 'us-east-1_OIzGC8iiM',
      userPoolClientId: '2vdackfotjqjld3idqf2dn67ea',
      passwordFormat: {
        minLength: 10,
        requireUppercase: true,
        requireLowercase: true,
        requireNumbers: true,
        requireSpecialCharacters: true
      },
      loginWith: {
        email: true,
        username: true
      }
    }
  }
})

root.render(
  <React.StrictMode>
    <QueryClientProvider client={client}>
      <RouterProvider router={router} />
    </QueryClientProvider>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
