import { ArrowLeftOutlined, EditOutlined } from '@ant-design/icons'
import { Button, Tabs } from 'antd'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import StickyLoading from './StickyLoading'
import ShareSticky from './share-sticky/ShareSticky'
import { useGetSticky } from '../../hooks/stickies'
import { useStickiesStore } from '../home-stickies/store/stickies-store'
import type { Sticky as StickyType } from '../../api/api-client'

export function StickyContent({
  sticky,
  readonly
}: {
  sticky: StickyType
  readonly?: boolean
}) {
  return (
    <div>
      {/* Header */}
      <div
        style={{
          margin: '20px 0',
          display: 'flex',
          flexDirection: 'column',
          rowGap: '16px'
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            rowGap: '5px'
          }}
        >
          <small style={{ color: '#777' }}>Title</small>
          <div style={{ fontSize: '16px' }}>{sticky.title}</div>
        </div>

        {/* Body */}
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            rowGap: '5px'
          }}
        >
          <small style={{ color: '#777' }}>Note</small>
          <div style={{ fontSize: '16px' }}>{sticky.note}</div>
        </div>
      </div>

      {/* Footer */}
      <div
        style={{
          padding: '16px 0px',
          borderTop: '1px solid #eee'
        }}
      >
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(auto-fill, minmax(150px, 1fr))',
            gap: '16px'
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              rowGap: '5px'
            }}
          >
            <small style={{ color: '#777' }}>Color</small>
            <div
              style={{
                background: sticky.color?.background,
                width: '25px',
                height: '25px',
                borderRadius: '50%'
              }}
            />
          </div>
          {!readonly && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                rowGap: '5px'
              }}
            >
              <small style={{ color: '#777' }}>Expanded state</small>
              <div>
                {sticky.expanded_state === 'collapsed'
                  ? 'Collapsed'
                  : 'Expanded'}
              </div>
            </div>
          )}
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              rowGap: '5px'
            }}
          >
            <small style={{ color: '#777' }}>Pinned</small>
            <div>{sticky.pinned ? 'Yes' : 'No'}</div>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              rowGap: '5px'
            }}
          >
            <small style={{ color: '#777' }}>Favourite</small>
            <div>{sticky.favourite ? 'Yes' : 'No'}</div>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              rowGap: '5px'
            }}
          >
            <small style={{ color: '#777' }}>Website</small>
            <div>{sticky.url?.domain}</div>
          </div>
          {!readonly && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                rowGap: '5px'
              }}
            >
              <small style={{ color: '#777' }}>Position</small>
              <div>
                X: {sticky.position?.x} | Y: {sticky.position?.y}
              </div>
            </div>
          )}

          {!readonly && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                rowGap: '5px'
              }}
            >
              <small style={{ color: '#777' }}>Font size</small>
              <div>{sticky.font_size}</div>
            </div>
          )}
          {!readonly && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                rowGap: '5px'
              }}
            >
              <small style={{ color: '#777' }}>Size</small>
              <div>
                W: {sticky.size?.width} | H: {sticky.size?.height}
              </div>
            </div>
          )}
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              rowGap: '5px'
            }}
          >
            <small style={{ color: '#777' }}>Create at</small>
            <div>
              {new Date(sticky.created_at as string).toLocaleDateString()}
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              rowGap: '5px'
            }}
          >
            <small style={{ color: '#777' }}>Update at</small>
            <div>
              {new Date(sticky.updated_at as string).toLocaleDateString()}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

interface Props {
  readonly?: boolean
  stickyId?: string
}
function Sticky(props: Props) {
  const params = useParams<{ stickyId: string }>()
  const [activeTab, setActiveTab] = useState<string>('details')

  const { sticky, isLoading } = useGetSticky({
    stickyId: (params.stickyId as string) || (props.stickyId as string)
  })
  const navigate = useNavigate()
  const setShowHeaderContent = useStickiesStore(
    (state) => state.setShowHeaderContent
  )

  useEffect(() => {
    setShowHeaderContent(false)

    return () => setShowHeaderContent(true)
  }, [setShowHeaderContent])

  return (
    <div>
      {!props.readonly && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '10px 0'
          }}
        >
          <Button
            onClick={() => navigate('/stickies/all')}
            style={{ padding: 0 }}
            type="link"
          >
            <ArrowLeftOutlined /> Back
          </Button>
          <Button
            ghost
            onClick={() => navigate(`/stickies/${params.stickyId}/edit`)}
            shape="round"
            size="small"
            type="primary"
            variant="filled"
          >
            Edit
            <EditOutlined />
          </Button>
        </div>
      )}

      {isLoading && <StickyLoading />}

      {!isLoading && (
        <React.Fragment>
          {!props.readonly && (
            <Tabs
              defaultActiveKey={activeTab}
              items={[
                {
                  key: 'details',
                  label: 'Details'
                },
                {
                  key: 'sharing',
                  label: 'Sharing'
                }
              ]}
              onChange={(event) => setActiveTab(event)}
            />
          )}

          {activeTab === 'details' && (
            <StickyContent readonly={props.readonly} sticky={sticky} />
          )}

          {activeTab === 'sharing' && (
            <ShareSticky stickyId={sticky.id as string} />
          )}
        </React.Fragment>
      )}
    </div>
  )
}

export default Sticky
