import { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { LoadingOutlined, LoginOutlined } from '@ant-design/icons'
import { Alert, Button, Typography } from 'antd'
import { useResetPassword } from './auth-hooks'
import InputC from '../shared/components/InputC'
import {
  IconCloudLock,
  IconLockSquareRounded,
  IconMail
} from '@tabler/icons-react'

const ResetPassword = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const resetPassword = useResetPassword()

  const state: { username: string } = location.state || {}

  const [error, setError] = useState('')
  const [username, setUsername] = useState(state.username || '')
  const [newPassword, setNewPassword] = useState('')
  const [verificationCode, setVerificationCode] = useState('')
  const [newPasswordConfirm, setNewPasswordConfirm] = useState('')

  const handleResetPassword = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    if (
      newPassword &&
      newPasswordConfirm &&
      newPassword !== newPasswordConfirm
    ) {
      setError('Passwords do not match!')

      return
    }

    setError('')

    resetPassword.mutate(
      {
        username,
        confirmationCode: verificationCode,
        newPassword
      },
      {
        onSuccess: () => {
          navigate('/auth/login', {
            state: {
              username
            }
          })
          setUsername('')
        },
        onError: (err) => {
          setError(err.message)
        }
      }
    )
  }

  return (
    <form
      onSubmit={handleResetPassword}
      style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        rowGap: '20px'
      }}
    >
      <div style={{ fontSize: '1.3rem' }}>Reset your password now.</div>

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          rowGap: '15px',
          width: '100%'
        }}
      >
        <InputC
          onChange={(e) => setUsername(e.target.value)}
          placeholder="Enter your Email or Username"
          prefix={<IconMail style={{ marginRight: '5px' }} />}
          required
          style={{ background: '#a2bcff18' }}
          type="text"
          value={username}
        />
        <InputC
          onChange={(e) => setVerificationCode(e.target.value)}
          placeholder="Verification code"
          prefix={<IconLockSquareRounded style={{ marginRight: '5px' }} />}
          required
          style={{ background: '#a2bcff18' }}
          type="text"
          value={verificationCode}
        />
        <InputC
          onChange={(e) => setNewPassword(e.target.value)}
          placeholder="New password"
          prefix={<IconCloudLock style={{ marginRight: '5px' }} />}
          required
          style={{ background: '#a2bcff18' }}
          type="text"
          value={newPassword}
        />
        <InputC
          onChange={(e) => setNewPasswordConfirm(e.target.value)}
          placeholder="Confirm new password"
          prefix={<IconCloudLock style={{ marginRight: '5px' }} />}
          required
          style={{ background: '#a2bcff18' }}
          type="text"
          value={newPasswordConfirm}
        />
        {error && (
          <Alert
            closable
            message={error}
            style={{ width: '100%' }}
            type="error"
          />
        )}
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            width: '100%',
            alignItems: 'center',
            marginTop: '10px'
          }}
        >
          <Button
            color="primary"
            disabled={!username || resetPassword.isPending}
            htmlType="submit"
            shape="round"
            variant="solid"
          >
            Reset Password{' '}
            {resetPassword.isPending ? <LoadingOutlined /> : <LoginOutlined />}
          </Button>
        </div>
      </div>
      <div style={{ textAlign: 'center' }}>
        <Typography.Link onClick={() => navigate('/auth/login')}>
          {' '}
          Back to Login!
        </Typography.Link>
      </div>
    </form>
  )
}

export default ResetPassword
