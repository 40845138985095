import { Outlet } from 'react-router-dom'

import { useMemo } from 'react'
import { useWindowSize } from 'react-use'
import image from '../assets/images/image.jpg'
import { IconCloud } from '@tabler/icons-react'

function Auth() {
  const { width } = useWindowSize()

  const isMobile = useMemo(() => width < 768, [width])
  const isTablet = useMemo(() => width < 1024, [width])

  const margin = useMemo(() => {
    if (isTablet && width > 768) return '50px'
    if (isMobile) return '10px'

    return '120px'
  }, [isMobile, isTablet, width])

  return (
    <div
      style={{
        width: '100vw',
        height: '100vh',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        padding: '20px'
      }}
    >
      {/* Left Section */}
      {!isMobile && (
        <div
          style={{
            flex: 1.5,
            width: '100%',
            height: '100%',
            background: 'rgba(255, 221, 159, 0)',
            borderRadius: '2rem',
            backgroundImage: `url(${image})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat'
          }}
        />
      )}

      {/* Right Section */}
      <div
        style={{
          flex: 1,
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'flex-start',
          margin: `0px ${margin}`,
          rowGap: '60px'
        }}
      >
        <div
          style={{
            color: '#dd9000',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            columnGap: '10px',
            fontSize: '2rem'
          }}
        >
          <IconCloud size={40} />
          <div>Stickies Cloud</div>
        </div>
        <Outlet />
      </div>
    </div>
  )
}

export default Auth
