import { Statistic, Typography } from 'antd'
import styled from 'styled-components'
import { useSearchStickies } from '../../hooks/stickies'
import Skeleton from 'react-loading-skeleton'
import { Sticky } from '../../api/api-client'
import _ from 'lodash'

function getMostActiveDomainAndAverage(data: Sticky[]) {
  const domainStats: Sticky = {} as Sticky

  // Aggregate data by domain
  data.forEach((sticky) => {
    const domain = sticky.url?.domain as string

    _.set(
      domainStats,
      domain + '.count',
      _.get(domainStats, domain + '.count', 0) + 1
    )
  })

  let mostActiveDomain = null
  let maxCount = 0
  let totalNotes = 0
  let domainCount = 0

  for (const domain in domainStats) {
    const count = _.get(domainStats, domain + '.count')

    totalNotes += count || 0
    domainCount += 1

    if (count > maxCount) {
      maxCount = count
      mostActiveDomain = domain
    }
  }

  const averageNotesPerDomain = totalNotes / domainCount

  return {
    mostActiveDomain,
    averageNotesPerDomain: averageNotesPerDomain.toFixed(0)
  }
}

function EngagementMetrics() {
  const { stickies, isLoading } = useSearchStickies({
    query: '',
    queryKey: 'EngagementMetrics'
  })

  const { mostActiveDomain, averageNotesPerDomain } =
    getMostActiveDomainAndAverage(stickies)

  return (
    <div>
      <Typography.Title level={4} style={{ color: '#525252' }}>
        Engagement Metrics
      </Typography.Title>
      <Container>
        <Wrapper>
          <Statistic
            title="Most Active Website"
            valueRender={() =>
              isLoading ? <Skeleton width={50} /> : mostActiveDomain
            }
            valueStyle={{ color: '#656565' }}
          />
        </Wrapper>
        <Wrapper>
          <Statistic
            title="Avg. Stickies / Website"
            valueRender={() =>
              isLoading ? <Skeleton width={50} /> : averageNotesPerDomain
            }
            valueStyle={{ color: '#656565' }}
          />
        </Wrapper>
      </Container>
    </div>
  )
}

export default EngagementMetrics

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr)); /* Default: 4 columns */
  gap: 20px;
  padding: 15px 0;
  border-radius: 16px;

  @media (max-width: 1024px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  @media (max-width: 768px) {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
`
const Wrapper = styled.div`
  border: 1px solid #dfdfdf;
  padding: 15px;
  border-radius: 16px;
`
