import { useMutation, useQuery } from '@tanstack/react-query'
import { useStickiesClient } from '../api/api-client'
import { Paths } from '@stickies/api-client'
import _ from 'lodash'

export type StickiesSearchQueryParams = Paths.SearchStickies.QueryParameters

export const useSearchStickiesMutation = () => {
  const apiClient = useStickiesClient()

  const response = useMutation({
    mutationFn: (params: StickiesSearchQueryParams) =>
      apiClient
        .searchStickies({
          ...params,
          query: params.query || ''
        })
        .then((res) => res.data)
  })

  const stickies = response.data?.results || []

  // sort by updated_at so that the most recent stickies are shown first
  stickies.sort((a, b) =>
    (b.updated_at || '').localeCompare(a.updated_at || '')
  )

  return {
    stickies,
    ...response
  }
}

export const useSearchStickies = (
  params: StickiesSearchQueryParams & {
    queryKey?: string
  }
) => {
  const apiClient = useStickiesClient()

  const paramsWithoutQueryKey = _.omit(params, 'queryKey')

  const response = useQuery({
    queryKey: [
      'SEARCH_STICKIES',
      Object.keys(params).join('-'),
      params.queryKey
    ],
    queryFn: () =>
      apiClient
        .searchStickies({
          ...paramsWithoutQueryKey,
          query: paramsWithoutQueryKey.query || ''
        })
        .then((res) => res.data)
  })

  const stickies = response.data?.results || []

  // sort by updated_at so that the most recent stickies are shown first
  stickies.sort((a, b) =>
    (b.updated_at || '').localeCompare(a.updated_at || '')
  )

  return {
    stickies,
    ...response
  }
}

export const useGetSticky = (params: { stickyId: string }) => {
  const apiClient = useStickiesClient()

  const response = useQuery({
    queryKey: ['GET_STICKY', params.stickyId],
    queryFn: () =>
      apiClient.getSticky({ id: params.stickyId }).then((res) => res.data)
  })

  const sticky = response.data || {}

  return {
    sticky,
    ...response
  }
}

export const usePreviewSticky = (token: string) => {
  const apiClient = useStickiesClient(token)

  const response = useQuery({
    queryKey: ['PREVIEW_STICKY'],
    queryFn: () => apiClient.previewSharedSticky().then((res) => res.data),
    refetchOnWindowFocus: false,
    retry: 1
  })

  const sticky = response.data || {}

  return {
    sticky,
    ...response
  }
}

interface SendEmailParams {
  sticky_id: string
  user_id: string
  user_name: string
  recipient_email: string
}
export const useSendEmail = () => {
  const apiClient = useStickiesClient()

  return useMutation({
    mutationFn: (params: SendEmailParams) =>
      apiClient
        .sendEmail(null, {
          recipient_email: params.recipient_email,
          shared_by_user_id: params.user_id,
          shared_by_user_name: params.user_name,
          sticky_id: params.sticky_id
        })
        .then((res) => res.data)
  })
}

export const useGetUserRelations = () => {
  const apiClient = useStickiesClient()

  const response = useQuery({
    queryKey: ['LIST_USER_RELATIONS'],
    queryFn: () => apiClient.getUserRelations().then((res) => res.data)
  })

  const relations = response.data?.results || []

  return {
    relations,
    ...response
  }
}

export const useGetStickySharedUsers = (params: { sticky_id: string }) => {
  const apiClient = useStickiesClient()

  const response = useQuery({
    queryKey: ['GET_STICKY_SHARED_USERS', params.sticky_id],
    queryFn: () =>
      apiClient
        .getStickySharedUsers({
          sticky_id: params.sticky_id
        })
        .then((res) => res.data)
  })

  const sharedUsers = response.data?.results || []

  return {
    sharedUsers,
    ...response
  }
}

export const useGetStickiesShared = (
  sharing_type: 'shared_by_me' | 'shared_with_me'
) => {
  const apiClient = useStickiesClient()

  const response = useQuery({
    queryKey: ['GET_STICKIES_SHARED', sharing_type],
    queryFn: () =>
      apiClient
        .getStickiesBySharingType({ sharing_type })
        .then((res) => res.data),
    enabled: Boolean(sharing_type)
  })

  const stickies = response.data?.results || []

  return {
    stickies,
    ...response
  }
}
