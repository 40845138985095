import { useQuery } from '@tanstack/react-query'
import { getCurrentUser } from 'aws-amplify/auth'

export const useCurrentUser = () => {
  const response = useQuery({
    queryKey: ['CURRENT_USER'],
    queryFn: () => getCurrentUser(),
    retry: 0
  })

  const userId = response.data?.userId

  return {
    userId,
    ...response
  }
}
