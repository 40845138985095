import { createBrowserRouter, Navigate } from 'react-router-dom'

import NotFoundPage from './invalid-route-page/InvalidRoutePage'
import App from '../App'
import Login from '../auth/Login'
import Signup from '../auth/Signup'
import ForgotPassword from '../auth/ForgotPassword'
import ResetPassword from '../auth/ResetPassword'
import Auth from '../auth/Auth'
import ConfirmSignup from '../auth/ConfirmSignup'
import Settings from '../settings/Settings'
import PinnedStickies from '../stickies/home-stickies/pinned-stickies/PinnedStickies'
import FavouriteStickies from '../stickies/home-stickies/favourite-stickies/FavouriteStickies'
import AllStickies from '../stickies/home-stickies/all-stickies/AllStickies'
import ArchivedStickies from '../stickies/home-stickies/archived-stickies/ArchivedStickies'
import SharedStickies from '../stickies/shared-stickies/SharedStickies'
import WithMe from '../stickies/shared-stickies/with-me/WithMe'
import ByMe from '../stickies/shared-stickies/by-me/ByMe'
import StickyEdit from '../stickies/sticky-edit/StickyEdit'
import Dashboard from '../dashboard/Dashboard'
import HomeStickies from '../stickies/home-stickies/HomeStickies'
import Sticky from '../stickies/sticky/Sticky'
import Subscription from '../payments/subscription/Subscription'
import Payments from '../payments/Payments'
import Billing from '../payments/billing/Billing'
import Activity from '../payments/activity/Activity'
import PaymentSettings from '../payments/payment-settings/PaymentSettings'
import StickySettings from '../settings/sticky-settings/StickySettings'
import NotificationsSettings from '../settings/notifications-settings/NotificationsSettings'
import ThemeSettings from '../settings/theme-settings/ThemeSettings'
import Preview from '../stickies/preview/Preview'
import ProtectedRoute from '../auth/ProtectedRoute'

export const router = createBrowserRouter([
  {
    path: '/',
    element: (
      <ProtectedRoute>
        <App />
      </ProtectedRoute>
    ),
    children: [
      {
        index: true,
        element: <Navigate replace to="/stickies/all" />
      },
      {
        path: 'stickies',
        element: <HomeStickies />,
        children: [
          {
            path: 'all',
            element: <AllStickies />
          },
          {
            path: 'pinned',
            element: <PinnedStickies />
          },
          {
            path: 'favourite',
            element: <FavouriteStickies />
          },
          {
            path: 'archived',
            element: <ArchivedStickies />
          },
          {
            path: ':stickyId/view',
            element: <Sticky />
          },
          {
            path: ':stickyId/edit',
            element: <StickyEdit />
          },
          {
            path: '*',
            element: <NotFoundPage />
          }
        ]
      },
      {
        path: 'shared',
        element: <SharedStickies />,
        children: [
          {
            index: true,
            element: <Navigate replace to="/shared/with-me" />
          },
          {
            path: 'with-me',
            element: <WithMe />
          },
          {
            path: 'by-me',
            element: <ByMe />
          },
          {
            path: '*',
            element: <NotFoundPage />
          }
        ]
      },
      {
        path: 'payments',
        element: <Payments />,
        children: [
          {
            index: true,
            element: <Navigate replace to="/payments/subscription" />
          },
          {
            path: 'subscription',
            element: <Subscription />
          },
          {
            path: 'billing',
            element: <Billing />
          },
          {
            path: 'activity',
            element: <Activity />
          },
          {
            path: 'settings',
            element: <PaymentSettings />
          },
          {
            path: '*',
            element: <NotFoundPage />
          }
        ]
      },
      {
        path: 'settings',
        element: <Settings />,
        children: [
          {
            index: true,
            element: <Navigate replace to="/settings/sticky" />
          },
          {
            path: 'sticky',
            element: <StickySettings />
          },
          {
            path: 'notifications',
            element: <NotificationsSettings />
          },
          {
            path: 'theme',
            element: <ThemeSettings />
          },
          {
            path: '*',
            element: <NotFoundPage />
          }
        ]
      },
      {
        path: 'dashboard',
        element: <Dashboard />
      }
    ]
  },
  {
    path: '/auth',
    element: (
      <ProtectedRoute failureRoute="/auth/login" successRoute="/">
        <Auth />
      </ProtectedRoute>
    ),
    children: [
      {
        index: true,
        element: <Navigate replace to="/auth/login" />
      },
      {
        path: 'login',
        element: <Login />
      },
      {
        path: 'signup',
        element: <Signup />
      },
      {
        path: 'forgot-password',
        element: <ForgotPassword />
      },
      {
        path: 'reset-password',
        element: <ResetPassword />
      },
      {
        path: 'confirm-signup',
        element: <ConfirmSignup />
      },
      {
        path: '*',
        element: <NotFoundPage />
      }
    ]
  },
  {
    path: '/preview',
    element: <Preview />
  },
  {
    path: '*',
    element: <NotFoundPage />
  }
])
