import { Components, getClient } from '@stickies/api-client'
import { useMemo } from 'react'

import * as config from './api-config'
import { configureClient } from './axios/api-utils'

export const getStickiesClient = (token?: string) => {
  const client = getClient()

  return configureClient(client, {
    baseURL: config.BASE_API_ENDPOINTS.API,
    token
  })
}

export const useStickiesClient = (token?: string) =>
  useMemo(() => getStickiesClient(token), [token])

export type Sticky = Components.Schemas.Sticky
export type User = Components.Schemas.User
