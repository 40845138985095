import {
  IconProps,
  IconStack3,
  IconPin,
  IconStar,
  IconArchive,
  IconUserDown,
  IconUserUp,
  IconBrandFeedly,
  IconMobiledata,
  IconReceipt2,
  IconSettingsDollar,
  IconFeatherFilled,
  IconSettingsSpark,
  IconBellCog
} from '@tabler/icons-react'
import { theme } from 'antd'
import styled from 'styled-components'

const iconMap: Record<string, React.FC<IconProps>> = {
  all: IconStack3,
  pinned: IconPin,
  favourite: IconStar,
  archived: IconArchive,
  'with-me': IconUserDown,
  'by-me': IconUserUp,
  subscription: IconBrandFeedly,
  activity: IconMobiledata,
  billing: IconReceipt2,
  settings: IconSettingsDollar,
  sticky: IconSettingsSpark,
  notifications: IconBellCog,
  theme: IconFeatherFilled
}

const labelMap: Record<string, string> = {
  all: 'All stickies',
  pinned: 'Pinned stickies',
  favourite: 'Favourite stickies',
  archived: 'Archived stickies',
  'with-me': 'Shared with me',
  'by-me': 'Shared by me',
  subscription: 'Subscription',
  activity: 'Activity',
  billing: 'Billing',
  settings: 'Settings',
  sticky: 'Sticky',
  notifications: 'Notifications',
  theme: 'Theme'
}

export const getTabItems = (tabKeys: string[], activeTab: string) => {
  return tabKeys.map((key) => {
    const IconComponent = iconMap[key]

    return {
      key,
      label: (
        <TabItem $isActive={(activeTab || tabKeys[0]) === key}>
          <IconComponent size={18} />
          <div>{labelMap[key]}</div>
        </TabItem>
      )
    }
  })
}

const TabItem = styled.div<{ $isActive: boolean }>`
  padding: 5px 15px;
  display: flex;
  align-items: center;
  gap: 8px;
  border-radius: 16px;
  cursor: pointer;

  color: ${({ $isActive }) =>
    $isActive ? theme.getDesignToken().blue : '#8b8b8b'};
  background: ${({ $isActive }) => ($isActive ? '#1777ff1a' : 'transparent')};

  &:hover {
    background: ${({ $isActive }) => ($isActive ? '#1777ff1a' : '#e6e6e65f')};
  }
`
