import { useCallback } from 'react'
import { useSearchStickies } from '../../../hooks/stickies'
import { Sticky } from '../../../api/api-client'
import { useNavigate } from 'react-router-dom'
import TableC from '../../../shared/components/Table'

function AllStickies() {
  const navigate = useNavigate()
  const { stickies, isLoading } = useSearchStickies({
    query: ''
  })

  const handleRowClick = useCallback(
    (sticky: Sticky) => {
      navigate(`/stickies/${sticky.id}/view`)
    },
    [navigate]
  )

  return (
    <TableC
      loading={isLoading}
      onRowClick={handleRowClick}
      stickies={stickies}
    />
  )
}

export default AllStickies
