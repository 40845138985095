import { useSearchStickies } from '../../../hooks/stickies'
import TableC from '../../../shared/components/Table'

function FavouriteStickies() {
  const { stickies, isLoading } = useSearchStickies({
    query: '',
    favourite: true
  })

  return <TableC loading={isLoading} stickies={stickies} />
}
export default FavouriteStickies
