import { useGetStickiesShared } from '../../../hooks/stickies'
import TableC from '../../../shared/components/Table'

function ByMe() {
  const { stickies, isLoading } = useGetStickiesShared('shared_by_me')

  return (
    <TableC loading={isLoading} location="shared_by_me" stickies={stickies} />
  )
}

export default ByMe
