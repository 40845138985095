import { Result } from 'antd'

import './invalid-route-page.css'

function InvalidRoutePage() {
  return (
    <Result
      status="403"
      subTitle="Sorry, you don't have access to this page."
      title="403"
    />
  )
}

export default InvalidRoutePage
