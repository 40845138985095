import { useEffect, useMemo, useState } from 'react'
import { Button, List, message, Select, Tooltip } from 'antd'
import { IconBrandTelegram, IconSearch } from '@tabler/icons-react'
import {
  useGetStickySharedUsers,
  useGetUserRelations,
  useSendEmail
} from '../../../hooks/stickies'
import { LoadingOutlined } from '@ant-design/icons'
import { fetchAuthSession } from 'aws-amplify/auth'
import UserItem from './UserItem'
import { useQueryClient } from '@tanstack/react-query'
import { User } from '../../../api/api-client'

interface Props {
  stickyId: string
}

function ShareSticky({ stickyId }: Props) {
  const sendEmail = useSendEmail()
  const { sharedUsers, isLoading: isLoadingSharedUsers } =
    useGetStickySharedUsers({
      sticky_id: stickyId as string
    })
  const [userPayload, setUserPayload] = useState<any>({})
  const [emails, setEmails] = useState<string[]>([])
  const queryClient = useQueryClient()

  const { relations, isLoading } = useGetUserRelations()

  const sharedUsersEmails = useMemo(
    () => sharedUsers.map((user) => user.email),
    [sharedUsers]
  )

  const selectOptions = useMemo(
    () =>
      relations
        .map((relation) => ({
          value: relation.email,
          label: relation.email
        }))
        .filter((email) => !sharedUsersEmails.includes(email.value)),
    [relations, sharedUsersEmails]
  )

  useEffect(() => {
    fetchAuthSession().then((res) => {
      setUserPayload({
        ...res.tokens?.idToken?.payload
      })
    })
  }, [])

  const handleShare = () => {
    if (!emails.length) {
      message.warning('Please enter a valid email address.')

      return
    }

    sendEmail.mutate(
      {
        sticky_id: stickyId,
        recipient_email: emails[0],
        user_id: userPayload['custom:userId'],
        user_name: userPayload['cognito:username']
      },
      {
        onSuccess: () => {
          setEmails([])
          message.success('Email sent successfully.')
          queryClient.invalidateQueries({
            queryKey: ['LIST_USER_RELATIONS']
          })
        }
      }
    )
  }

  return (
    <div>
      <List
        bordered
        dataSource={sharedUsers as User[]}
        header={
          <div style={{ fontSize: '16px', fontWeight: 500 }}>
            Already shared with
          </div>
        }
        loading={isLoadingSharedUsers}
        renderItem={(user) => <UserItem key={user.id} user={user} />}
        style={{ marginBottom: '20px' }}
      />

      <div style={{ display: 'flex', width: '100%', gap: '10px' }}>
        <Select
          loading={isLoading}
          mode="tags"
          onDeselect={(value) =>
            setEmails((prevValue) => prevValue.filter((item) => item !== value))
          }
          onSelect={(value) => setEmails((prevValue) => [...prevValue, value])}
          options={selectOptions}
          placeholder="Enter user emails"
          style={{
            minHeight: '40px',
            width: '100%'
          }}
          suffixIcon={
            <Tooltip title="Clear">
              <IconSearch
                onClick={() => setEmails([])}
                size={20}
                style={{ color: 'black' }}
              />
            </Tooltip>
          }
          value={emails}
        />
        <Button
          disabled={sendEmail.isPending || !emails.length}
          onClick={handleShare}
          shape="round"
          style={{
            height: '40px',
            borderRadius: '24px'
          }}
          type="primary"
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '10px'
            }}
          >
            {sendEmail.isPending ? (
              <LoadingOutlined />
            ) : (
              <IconBrandTelegram size={20} />
            )}
          </div>
          Share Sticky
        </Button>
      </div>
    </div>
  )
}

export default ShareSticky
