import { Tabs } from 'antd'
import { useCallback, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useStickiesStore } from './store/stickies-store'
import { getTabItems } from '../../shared/components/TabItems'
import './tabs.css'
import { StickiesLayout } from '../StickiesLayout'

function HomeStickies() {
  const location = useLocation()
  const navigate = useNavigate()

  const setShowHeaderContent = useStickiesStore(
    (state) => state.setShowHeaderContent
  )
  const showHeaderContent = useStickiesStore((state) => state.showHeaderContent)

  const [activeTab, setActiveTab] = useState<string>(
    location.pathname.split('/')[2]
  )

  const items = getTabItems(
    ['all', 'pinned', 'favourite', 'archived'],
    activeTab
  )

  useEffect(() => {
    // check if the current route matches a main tab route
    const pathParts = location.pathname.split('/')
    const isMainTabRoute =
      pathParts[1] === 'stickies' &&
      items?.some((item) => item.key === pathParts[2])

    setShowHeaderContent(isMainTabRoute as boolean)
  }, [items, location.pathname, setShowHeaderContent])

  const onChange = useCallback(
    (key: string) => {
      setActiveTab(key)
      navigate(key)
    },
    [navigate]
  )

  return (
    <div style={{ display: 'flex', flexDirection: 'column', rowGap: '16px' }}>
      <StickiesLayout
        showHeaderContent={showHeaderContent}
        tabs={
          <Tabs
            className="custom-tabs"
            defaultActiveKey={activeTab}
            items={items}
            onChange={onChange}
          />
        }
      />
    </div>
  )
}

export default HomeStickies
