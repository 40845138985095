import { useSearchParams } from 'react-router-dom'
import { usePreviewSticky } from '../../hooks/stickies'
// import { LoadingOutlined } from '@ant-design/icons'
import { Alert, Button } from 'antd'
import { IconUserPlus } from '@tabler/icons-react'
import { StickyContent } from '../sticky/Sticky'
import { useMemo } from 'react'
import InvalidRoutePage from '../../routes/invalid-route-page/InvalidRoutePage'

function Preview() {
  const [searchParams] = useSearchParams()

  const token = searchParams.get('token') as string

  const { sticky, isError, error } = usePreviewSticky(token)

  const isStickyExtensionInsalled = useMemo(() => {
    // check for element with id 'stickies-extension-root'
    const element = document.getElementById('stickies-extension-root')

    return element !== null
  }, [])

  // if (isLoading) {
  //   return (
  //     <div
  //       style={{
  //         display: 'flex',
  //         justifyContent: 'center',
  //         alignItems: 'center',
  //         height: '100vh',
  //         width: '100vw'
  //       }}
  //     >
  //       <LoadingOutlined style={{ fontSize: 50, color: 'gold' }} />
  //     </div>
  //   )
  // }

  if (!token) {
    return (
      <div
        style={{
          width: '100vw',
          height: '100vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <InvalidRoutePage />
      </div>
    )
  }

  if (isError && error.message === 'Forbidden') {
    return (
      <div
        style={{
          width: '100vw',
          height: '100vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <InvalidRoutePage />
      </div>
    )
  }

  return (
    <div
      style={{
        padding: '3rem',
        display: 'flex',
        flexDirection: 'column',
        gap: '30px'
      }}
    >
      {!isStickyExtensionInsalled && (
        <Alert
          description={
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                gap: '10px',
                alignItems: 'flex-start'
              }}
            >
              <div>
                You seems to have not installed Stickies Extension, Please
                install it to preview it on the website or refresh the page if
                you have already installed it. The extension will help you get
                the most out of Stickies. Register your account to get access to
                all the features.
              </div>
              <div
                style={{
                  display: 'flex',
                  width: '100%',
                  justifyContent: 'flex-end'
                }}
              >
                <Button
                  color="primary"
                  shape="round"
                  size="middle"
                  variant="outlined"
                >
                  Register Now <IconUserPlus size={16} />
                </Button>
              </div>
            </div>
          }
          message="Install Stickies Extension"
          showIcon
          type="info"
        />
      )}

      <h3>Sticky Preview</h3>

      <StickyContent readonly sticky={sticky} />

      <div
        style={{
          marginTop: '50px',
          display: 'flex',
          gap: '20px',
          justifyContent: 'flex-end',
          flexWrap: 'wrap-reverse',
          alignItems: 'flex-end'
        }}
      ></div>
    </div>
  )
}

export default Preview
