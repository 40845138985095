import React from 'react'
import { Drawer } from 'antd'
import './drawer.css'
import { IconCloud } from '@tabler/icons-react'

interface Props {
  content: React.ReactNode
  open: boolean
  setOpen: (value: boolean) => void
}

function DrawerC(props: Props) {
  const onClose = () => {
    props.setOpen(false)
  }

  return (
    <Drawer
      closable={false}
      closeIcon={null}
      key={'drawer'}
      onClick={onClose}
      onClose={onClose}
      open={props.open}
      placement={'left'}
      rootStyle={{ padding: 0 }}
      styles={{ body: { padding: '0 10px' } }}
      title={
        <div
          style={{
            color: '#dd9a1d',
            fontWeight: 'bold',
            textAlign: 'center',
            fontSize: '16px',
            height: '35px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '10px'
          }}
        >
          <IconCloud color={'#dd9a1d'} size={25} />
          {'Stickies Cloud'}
        </div>
      }
      width={250}
    >
      {props.content}
    </Drawer>
  )
}

export default DrawerC
