import React from 'react'
import { Input } from 'antd'
import type { GetProps } from 'antd'

type SearchProps = GetProps<typeof Input.Search>

const { Search } = Input

const SearchInput = (props: SearchProps) => <Search enterButton {...props} />

export default SearchInput
