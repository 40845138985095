import { Tabs } from 'antd'
import { useState, useCallback } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import { useLocation } from 'react-use'
import { getTabItems } from '../shared/components/TabItems'

function Settings() {
  const location = useLocation()
  const navigate = useNavigate()
  const [activeTab, setActiveTab] = useState<string>(
    location.pathname?.split('/')[2] as string
  )

  const items = getTabItems(['sticky', 'notifications', 'theme'], activeTab)

  const onChange = useCallback(
    (key: string) => {
      setActiveTab(key)
      navigate(key)
    },
    [navigate]
  )

  return (
    <div style={{ display: 'flex', flexDirection: 'column', rowGap: '16px' }}>
      <Tabs
        className="custom-tabs"
        defaultActiveKey={activeTab}
        items={items}
        onChange={onChange}
      />
      <Outlet />
    </div>
  )
}

export default Settings
