import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

function StickyLoading() {
  return (
    <div>
      {/* Sticky Details start here */}
      <div>
        {/* Header */}
        <div
          style={{
            margin: '20px 0',
            display: 'flex',
            flexDirection: 'column',
            rowGap: '16px'
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              rowGap: '5px'
            }}
          >
            <small>Title</small>
            <Skeleton height={16} width={200} />
          </div>

          {/* Body */}
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              rowGap: '5px'
            }}
          >
            <small>Note</small>
            <Skeleton height={16} width={500} />
          </div>
        </div>

        {/* Footer */}
        <div
          style={{
            padding: '16px 0px',
            borderTop: '1px solid #eee'
          }}
        >
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: 'repeat(auto-fill, minmax(150px, 1fr))',
              gap: '16px'
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                rowGap: '5px'
              }}
            >
              <small>Color</small>
              <Skeleton borderRadius={50} height={25} width={25} />
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                rowGap: '5px'
              }}
            >
              <small>Expanded state</small>
              <Skeleton height={16} width={150} />
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                rowGap: '5px'
              }}
            >
              <small>Pinned</small>
              <Skeleton height={16} width={150} />
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                rowGap: '5px'
              }}
            >
              <small>Favourite</small>
              <Skeleton height={16} width={150} />
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                rowGap: '5px'
              }}
            >
              <small>Domain</small>
              <Skeleton height={16} width={150} />
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                rowGap: '5px'
              }}
            >
              <small>Position</small>
              <Skeleton height={16} width={150} />
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                rowGap: '5px'
              }}
            >
              <small>Font size</small>
              <Skeleton height={16} width={150} />
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                rowGap: '5px'
              }}
            >
              <small>Size</small>
              <Skeleton height={16} width={150} />
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                rowGap: '5px'
              }}
            >
              <small>Create at</small>
              <Skeleton height={16} width={150} />
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                rowGap: '5px'
              }}
            >
              <small>Update at</small>
              <Skeleton height={16} width={150} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default StickyLoading
