import { Layout, Menu, theme } from 'antd'
import React, { useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useWindowSize } from 'react-use'
import DrawerC from './shared/components/drawer/Drawer'
import { useAppStore } from './stores/app-store'
import {
  IconCards,
  IconCloud,
  IconCreditCard,
  IconDeviceDesktopAnalytics,
  IconLocationShare,
  IconProps,
  IconSettings
} from '@tabler/icons-react'

const icons: Record<string, React.FC<IconProps>> = {
  stickies: IconCards,
  shared: IconLocationShare,
  dashboard: IconDeviceDesktopAnalytics,
  payments: IconCreditCard,
  settings: IconSettings
}

const menuItems = [
  { key: 'stickies', label: 'Stickies' },
  { key: 'shared', label: 'Shared stickies' },
  { key: 'dashboard', label: 'Dashboard' },
  { key: 'payments', label: 'Payments' },
  { key: 'settings', label: 'Settings' }
]

interface Props {
  collapsed: boolean
}

function SideBarMenu(props: Props) {
  const navigate = useNavigate()

  const [selectedKey, setSelectedKey] = useState<string>('stickies')

  const items = useMemo(
    () =>
      menuItems.map(({ key, label }) => {
        const IconComponent = icons[key]
        const isSelected = selectedKey === key

        // item styles
        const itemColor = isSelected ? theme.getDesignToken().blue : '#7e7e7e'

        // icon styles
        const size = props.collapsed ? 23 : 20
        const color = isSelected ? theme.getDesignToken().blue : '#7e7e7e'

        return {
          key,
          label,
          className: 'menu-item',
          icon: <IconComponent color={color} size={size} />,
          style: {
            color: itemColor
          }
        }
      }),
    [props.collapsed, selectedKey]
  )

  return (
    <Menu
      defaultSelectedKeys={['stickies']}
      mode="inline"
      onSelect={(element) => {
        if (element.key === 'stickies') {
          navigate(element.key + '/all')
        } else {
          navigate(element.key)
        }

        setSelectedKey(element.key)
      }}
      style={{ border: 'none' }}
      theme="light"
    >
      {items.map((item) => (
        <Menu.Item
          key={item?.key}
          style={{
            borderRadius: '16px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '15px'
            }}
          >
            {item?.icon}
            {!props.collapsed && item?.label}
          </div>
        </Menu.Item>
      ))}
    </Menu>
  )
}

function AppSidebar(props: Props) {
  const { Sider } = Layout
  const { width } = useWindowSize()

  const isMobile = width < 768
  const toggleSideBar = useAppStore((state) => state.toggleSideBar)
  const setToggleSideBar = useAppStore((state) => state.setToggleSideBar)

  if (isMobile) {
    return (
      <DrawerC
        content={<SideBarMenu collapsed={props.collapsed} />}
        open={toggleSideBar}
        setOpen={(value) => setToggleSideBar(value)}
      />
    )
  }

  return (
    <Sider
      collapsed={props.collapsed}
      collapsible
      style={{ padding: '0 10px', borderRight: '1px solid #e6e6e688' }}
      theme="light"
      trigger={null}
      width={250}
    >
      <div
        style={{
          fontWeight: 'bold',
          textAlign: 'center',
          fontSize: '16px',
          height: '64px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: '10px',
          padding: '0 10px',
          borderBottom: '1px solid #e6e6e688'
        }}
      >
        <IconCloud color={'#dd9a1d'} size={props.collapsed ? 35 : 25} />
        {!props.collapsed && 'Stickies Cloud'}
      </div>
      <SideBarMenu collapsed={props.collapsed} />
    </Sider>
  )
}

export default AppSidebar
