import { useEffect } from 'react'
import { useStickiesStore } from '../home-stickies/store/stickies-store'
import { ArrowLeftOutlined, SaveOutlined } from '@ant-design/icons'
import { Button, Input, Select, Switch } from 'antd'
import { useNavigate, useParams } from 'react-router-dom'
import { Sticky } from '../../api/api-client'
import { useGetSticky } from '../../hooks/stickies'
import StickyLoading from '../sticky/StickyLoading'

const TextArea = Input.TextArea

function StickyContentEdit({ sticky }: { sticky: Sticky }) {
  return (
    <div>
      {/* Header */}
      <div
        style={{
          margin: '20px 0',
          display: 'flex',
          flexDirection: 'column',
          rowGap: '16px'
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            rowGap: '5px'
          }}
        >
          <small style={{ color: '#777' }}>Title</small>
          <Input onChange={() => ''} placeholder="Title" value={sticky.title} />
        </div>

        {/* Body */}
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            rowGap: '5px'
          }}
        >
          <small style={{ color: '#777' }}>Note</small>
          <TextArea
            maxLength={1000}
            onChange={() => ''}
            placeholder="Note"
            rows={4}
            value={sticky.note}
          />
        </div>
      </div>

      {/* Footer */}
      <div
        style={{
          padding: '16px 0px',
          borderTop: '1px solid #eee'
        }}
      >
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(auto-fill, minmax(150px, 1fr))',
            gap: '16px'
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              rowGap: '5px'
            }}
          >
            <small style={{ color: '#777' }}>Color</small>
            <Select defaultValue={sticky.color?.background} onChange={() => ''}>
              <Select.Option value="#ffffff">
                <div
                  style={{
                    background: sticky.color?.background,
                    width: '25px',
                    height: '25px',
                    borderRadius: '50%'
                  }}
                />
                White
              </Select.Option>
              <Select.Option value="#000000">
                <div
                  style={{
                    background: sticky.color?.background,
                    width: '25px',
                    height: '25px',
                    borderRadius: '50%'
                  }}
                />
                Black
              </Select.Option>
              <Select.Option value="#ff0000">
                <div
                  style={{
                    background: sticky.color?.background,
                    width: '25px',
                    height: '25px',
                    borderRadius: '50%'
                  }}
                />
                Red
              </Select.Option>
              <Select.Option value="#00ff00">
                <div
                  style={{
                    background: sticky.color?.background,
                    width: '25px',
                    height: '25px',
                    borderRadius: '50%'
                  }}
                />
                Green
              </Select.Option>
              <Select.Option value="#0000ff">
                <div
                  style={{
                    background: sticky.color?.background,
                    width: '25px',
                    height: '25px',
                    borderRadius: '50%'
                  }}
                />
                Blue
              </Select.Option>
            </Select>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              rowGap: '5px'
            }}
          >
            <small style={{ color: '#777' }}>Expanded state</small>
            <Switch
              checkedChildren="Expanded"
              defaultChecked={sticky.expanded_state === 'collapsed'}
              onChange={() => ''}
              style={{ minWidth: '50px', maxWidth: '100px' }}
              unCheckedChildren="Collapsed"
            />
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              rowGap: '5px'
            }}
          >
            <small style={{ color: '#777' }}>Pinned</small>
            <Switch
              checkedChildren="Yes"
              defaultChecked={sticky.pinned}
              onChange={() => ''}
              style={{ minWidth: '50px', maxWidth: '100px' }}
              unCheckedChildren="No"
            />
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              rowGap: '5px'
            }}
          >
            <small style={{ color: '#777' }}>Favourite</small>
            <Switch
              checkedChildren="Yes"
              defaultChecked={sticky.favourite}
              onChange={() => ''}
              style={{ minWidth: '50px', maxWidth: '100px' }}
              unCheckedChildren="No"
            />
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              rowGap: '5px'
            }}
          >
            <small style={{ color: '#777' }}>Font size</small>
            <Select defaultValue={sticky.font_size} onChange={() => ''}>
              <Select.Option value="16">16</Select.Option>
              <Select.Option value="20">20</Select.Option>
              <Select.Option value="24">24</Select.Option>
              <Select.Option value="28">28</Select.Option>
              <Select.Option value="32">32</Select.Option>
            </Select>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              rowGap: '5px'
            }}
          >
            <small style={{ color: '#777' }}>Domain</small>
            <Input
              defaultValue={sticky.url?.domain}
              disabled
              placeholder="Domain"
            />
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              rowGap: '5px'
            }}
          >
            <small style={{ color: '#777' }}>Position</small>
            <Input
              defaultValue={`X: ${sticky.position?.x} | Y: ${sticky.position?.y}`}
              disabled
              placeholder="Position"
            />
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              rowGap: '5px'
            }}
          >
            <small style={{ color: '#777' }}>Size</small>
            <Input
              defaultValue={`W: ${sticky.size?.width} | H: ${sticky.size?.height}`}
              disabled
              placeholder="Size"
            />
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              rowGap: '5px'
            }}
          >
            <small style={{ color: '#777' }}>Create at</small>
            <Input
              defaultValue={new Date(
                sticky.created_at as string
              ).toLocaleDateString()}
              disabled
              placeholder="Create at"
            />
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              rowGap: '5px'
            }}
          >
            <small style={{ color: '#777' }}>Update at</small>
            <Input
              defaultValue={new Date(
                sticky.updated_at as string
              ).toLocaleDateString()}
              disabled
              placeholder="Update at"
            />
          </div>
        </div>
      </div>
    </div>
  )
}

function StickyEdit() {
  const params = useParams<{ stickyId: string }>()

  const { sticky, isLoading } = useGetSticky({
    stickyId: params.stickyId as string
  })
  const setShowHeaderContent = useStickiesStore(
    (state) => state.setShowHeaderContent
  )

  useEffect(() => {
    setShowHeaderContent(false)

    return () => setShowHeaderContent(true)
  }, [setShowHeaderContent])
  const navigate = useNavigate()

  useEffect(() => {
    setShowHeaderContent(false)

    return () => setShowHeaderContent(true)
  }, [setShowHeaderContent])

  return (
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '10px 0'
        }}
      >
        <Button
          danger
          onClick={() => navigate(-1)}
          style={{ padding: 0 }}
          type="link"
        >
          <ArrowLeftOutlined /> Cancel
        </Button>
        <Button
          ghost
          shape="round"
          size="small"
          type="primary"
          variant="filled"
        >
          Save Changes
          <SaveOutlined />
        </Button>
      </div>

      {isLoading && <StickyLoading />}
      {!isLoading && <StickyContentEdit sticky={sticky} />}
    </div>
  )
}

export default StickyEdit
