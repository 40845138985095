import { useSearchStickies } from '../../../hooks/stickies'
import TableC from '../../../shared/components/Table'

function PinnedStickies() {
  const { stickies, isLoading } = useSearchStickies({
    query: '',
    pinned: true
  })

  return <TableC loading={isLoading} stickies={stickies} />
}

export default PinnedStickies
