import { useMutation } from '@tanstack/react-query'
import {
  signUp,
  confirmSignUp,
  resetPassword,
  signIn,
  confirmResetPassword
} from 'aws-amplify/auth'

export const useSignup = () =>
  useMutation({
    mutationFn: ({
      email,
      username,
      password
    }: {
      email: string
      username: string
      password: string
    }) =>
      signUp({
        username,
        password,
        options: {
          userAttributes: {
            email
          }
        }
      })
  })

export const useConfirmSignup = () =>
  useMutation({
    mutationFn: ({
      username,
      confirmationCode
    }: {
      username: string
      confirmationCode: string
    }) =>
      confirmSignUp({
        username,
        confirmationCode
      })
  })

export const useForgetPassword = () =>
  useMutation({
    mutationFn: ({ username }: { username: string }) =>
      resetPassword({
        username
      })
  })

export const useLogin = () =>
  useMutation({
    mutationFn: ({
      username,
      password
    }: {
      username: string
      password: string
    }) =>
      signIn({
        username,
        password
      })
  })

export const useResetPassword = () =>
  useMutation({
    mutationFn: ({
      username,
      confirmationCode,
      newPassword
    }: {
      username: string
      confirmationCode: string
      newPassword: string
    }) =>
      confirmResetPassword({
        username,
        confirmationCode,
        newPassword
      })
  })
