import { Input, InputProps } from 'antd'

function InputC(props: InputProps) {
  return (
    <Input
      {...props}
      style={{
        borderWidth: '2px',
        fontSize: '18px',
        padding: '10px 15px',
        borderRadius: '16px',
        ...props.style
      }}
    />
  )
}

export default InputC
